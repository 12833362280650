import { LibraryIcon, HomeIcon } from "@heroicons/react/solid";
import React, { useContext, useState } from "react";
import { GlobalContext } from "../context/GlobalState";
import H2 from "./Content/H2";
import H3 from "./Content/H3";

import DocField from "./Form/DocField";
import NumberInput from "./Form/NumerInput";
import TextField, { FieldDummy } from "./Form/TextField";
import RegistrationHeader from "./RegistrationHeader";
import { useForm } from "react-hook-form";


export default function HomeContent3() {

    const { setBaseInfo, baseInfo } = useContext(GlobalContext);

    const defaultValues = {
        kaufpreis: 1050000,

    };

    const form = useForm(
        { defaultValues }
    );

    const kaufpreis = form.watch("kaufpreis");



    const getMail = () => {
        if (baseInfo && "email" in baseInfo) {
            return baseInfo.email;
        } else {
            return ""
        }
    }
    const email = getMail();

    const [formState, setFormState] = useState({
        name: baseInfo?.name || "",
        adresse: baseInfo?.adresse || "",
        telefon: baseInfo?.telefon || "",
        geburtsdatum: baseInfo?.geburtsdatum || "",
        vorname: baseInfo?.vorname || "",
        plz: baseInfo?.plz || "",
        ort: baseInfo?.ort || "",
        email: baseInfo?.email || "",
        datenUebernahme: true,
        eigentuemerSchaft: baseInfo?.eigentuemerSchaft,
        art: baseInfo?.art || "wohnung"
    });

    const handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setFormState({
            ...formState,
            [name]: value,
        })
    }

    return (
        <div className="py-16 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden text-left">
            <div className="max-w-max lg:max-w-7xl mx-auto px-2">
                <div className="relative z-10 mb-8 md:mb-2 md:px-6">

                    <RegistrationHeader
                        active={2}
                        title="Finanzierungsantrag"
                    />

                    <H2>
                        Art der Wunschimmobilie
                    </H2>

                </div>

                <div className="relative z-10 my-8 md:mb-2 md:px-6 flex">

                    <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-theme_blue pb-8">
                        Möchtest du den Antrag für ein Haus oder eine Stock&shy;werks&shy;eig&shy;en&shy;tums&shy;wohn&shy;ung erfassen?
                    </h2>

                    <div
                        className="flex gap-8 pl-8"
                    >
                        <button
                            className={`bg-theme_green flex flex-col
                             items-center justify-center h-[180px] w-[180px] text-white text-2xl 
                             font-bold transition-colors border-2
                             ${formState.art === "haus" ? "bg-theme_blue" : "hover:bg-theme_orange"}
                             `}
                            onClick={() => setFormState({ ...formState, art: "haus" })}
                        >
                            <HomeIcon
                                className="w-1/2"
                            />
                            Haus
                        </button>
                        <button
                            className={`bg-theme_green flex flex-col
                            items-center justify-center h-[180px] w-[180px] text-white text-2xl
                        font-bold transition-colors 
                        ${formState.art === "wohnung" ? "bg-theme_blue" : "hover:bg-theme_orange"}
                             `}
                            onClick={() => setFormState({ ...formState, art: "wohnung" })}
                        >
                            <LibraryIcon
                                className="w-1/2"
                            />
                            Wohnung
                        </button>
                    </div>

                </div>


                <div className="pt-24 bg-white overflow-hidden text-left px-6">
                    <div className="max-w-max lg:max-w-7xl mx-auto">
                        <div className="relative mb-8">
                            <H2>
                                Angaben zur Immobilie
                            </H2>
                        </div>
                    </div>
                    <div className="relative">

                        <div className="relative md:bg-white">
                            <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                    <TextField
                                        id="strasse"
                                        label="Strasse"
                                        type="text"
                                        value="Dorfstrasse"
                                    />
                                    <TextField
                                        id="plz"
                                        label="PLZ"
                                        type="text"
                                        value="6005"
                                    />

                                    <NumberInput
                                        id="kaufpreis"
                                        label="Kaufpreis"
                                        placeholder={1000000}
                                        step={10000}
                                        value={kaufpreis}
                                        // min={600000}
                                        min={0}
                                        max={2500000}
                                        control={form.control}
                                    />
                                </div>
                                <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                                    <TextField
                                        id="nummer"
                                        label="Nummer"
                                        type="text"
                                        value="42"
                                    />

                                    <TextField
                                        id="ort"
                                        label="Ort"
                                        type="text"
                                        value="Luzern"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {formState.art === "wohnung" &&

                    <div id="dokumente-wohnung" className="pb-8 bg-white overflow-hidden text-left px-6">
                        <div className="max-w-max lg:max-w-7xl mx-auto">
                            <div className="relative z-10 mb-8 md:mb-2 md:px-6"></div>

                            <H3>
                                Dokumente
                            </H3>


                        </div>
                        <div className="relative">

                            <div className="relative md:bg-white pb-8">
                                <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                    <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                        <DocField
                                            id="name"
                                            label="Situations- / Katasterplan"
                                            type="text"
                                            placeholder="Mustermann"
                                            autoFocus={true}
                                        />
                                        <DocField
                                            id="adresse"
                                            label="Gebäudeversicherungsnachweis"
                                            type="text"
                                            placeholder="Dorfstrasse 42"
                                        />
                                        <DocField
                                            id="telefon"
                                            label="Verkaufsdokumentation"
                                            type="tel"
                                            placeholder="XXX XXX XX XX"
                                        />
                                        <DocField
                                            id="vorname"
                                            label="Letztjährige Betriebs- und Nebenkostenabrechnung"
                                            type="text"
                                            placeholder="Hans"
                                        />
                                    </div>
                                    <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                        <DocField
                                            id="vorname"
                                            label="Plan / Grundriss des Gebäudes"
                                            type="text"
                                            placeholder="Hans"
                                        />
                                        <DocField
                                            id="plz"
                                            label="Fotos der Liegenschaft innen und aussen"
                                            type="text"
                                            placeholder="XXXX"
                                        />
                                        <DocField
                                            id="ort"
                                            label="Stockwerkeigentumsreglement"
                                            type="text"
                                            placeholder="Luzern"
                                        />
                                        <DocField
                                            id="ort"
                                            label="Plan der Stockwerkeinheit mit Nettofläche in m&sup2;"
                                            type="text"
                                            placeholder="Luzern"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }


                {formState.art === "haus" &&
                    <div id="dokumente-haus" className="pb-8 bg-white overflow-hidden text-left px-6">
                        <div className="max-w-max lg:max-w-7xl mx-auto">
                            <div className="relative z-10 mb-8 md:mb-2 md:px-6"></div>

                            <H3>
                                Dokumente
                            </H3>


                        </div>
                        <div className="relative">

                            <div className="relative md:bg-white pb-8">
                                <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                    <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                        <DocField
                                            id="name"
                                            label="Situations- / Katasterplan"
                                            type="text"
                                            placeholder="Mustermann"
                                            autoFocus={true}
                                        />
                                        <DocField
                                            id="adresse"
                                            label="Gebäudeversicherungsnachweis"
                                            type="text"
                                            placeholder="Dorfstrasse 42"
                                        />
                                        <DocField
                                            id="telefon"
                                            label="Verkaufsdokumentation"
                                            type="tel"
                                            placeholder="XXX XXX XX XX"
                                        />
                                    </div>
                                    <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                        <DocField
                                            id="vorname"
                                            label="Plan / Grundriss des Gebäudes"
                                            type="text"
                                            placeholder="Hans"
                                        />
                                        <DocField
                                            id="plz"
                                            label="Fotos der Liegenschaft innen und aussen"
                                            type="text"
                                            placeholder="XXXX"
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="mt-24 flex justify-center">
                    <a
                        href="/projekte"
                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white bg-theme_green hover:bg-theme_blue transition-colors font-bold"
                    >
                        Antrag abschicken
                    </a>
                </div>

            </div>
        </div>
    )
}