import React from 'react';

import TopBar from '../components/TopBar';
import Base from '../layout/base';
import HomeContent3 from '../components/HomeContent3';

function App() {
  return (
    <Base>
      <div className="App">
        <TopBar />
        <HomeContent3 />
      </div>
    </Base>
  );
}

export default App;
