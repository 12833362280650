import React from "react";
import NumberFormat from 'react-number-format';
import { Controller } from "react-hook-form";
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';


export default function NumberInput({
    id,
    label,
    placeholder = "0.00",
    autoFocus = false,
    value,
    onChange = () => { },
    step = 10000,
    min,
    max,
    control
}) {
    return (
        <div
            key={id}
            className="relative pb-2 mb-2 flex justify-between text-md items-center border-b-2 border-theme_blue border-x-none border-t-none"
        >
            <label htmlFor={id} className="block text-md text-gray-700 font-bold">
                {label}:
            </label>
            <div className="mt-1 w-[70%]">

                <Controller
                    control={control}
                    name={id}
                    defaultValue={value}
                    render={({ field: { name, value, ref, onChange } }) => (
                        <>
                            <div className='relative'>
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500">CHF</span>
                                </div>
                                <NumberFormat
                                    // format="#### #### #### ####"
                                    thousandSeparator={'\''}
                                    decimalScale={2}
                                    name={name}
                                    value={value}
                                    ref={ref}
                                    onValueChange={({ value }) => {
                                        onChange(parseInt(value))
                                    }}
                                    className="focus:ring-theme_blue focus:border-theme_blue block w-full pl-12 text-right text-theme_blue border-gray-300"
                                />
                            </div>
                        </>
                    )}
                />
            </div>
        </div>
    )
}